import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VRow,{staticClass:"mx-2 pt-2"},[_c(VCol,[_c('h3',[_vm._v("Daftar User")])])],1),_c(VDivider,{staticClass:"my-4"}),_c('div',{staticClass:"px-4 pb-4"},[_c(VRow,{staticClass:"mb-1"},[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VSelect,{staticClass:"w-25",attrs:{"items":_vm.sortOptions,"item-text":"label","item-value":"id","label":"Sort","outlined":"","dense":"","return-object":"","hide-details":"auto"},on:{"change":_vm.changeSortUser},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between flex-grow-1"},[_c('div',{staticClass:"font-weight-bold"},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"26"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.label))])],1)])]}}]),model:{value:(_vm.selectedSort),callback:function ($$v) {_vm.selectedSort=$$v},expression:"selectedSort"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{staticClass:"w-100",attrs:{"outlined":"","dense":"","label":"Cari user","hide-details":"auto"},on:{"change":_vm.searchUser},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VSelect,{staticClass:"w-25",attrs:{"items":_vm.statusOptions,"item-text":"label","item-value":"id","label":"Status","outlined":"","dense":"","return-object":"","hide-details":"auto"},on:{"change":_vm.changeStatusUser},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between flex-grow-1"},[_c('div',{staticClass:"font-weight-bold"},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"26"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.label))])],1)])]}}]),model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)],1),(_vm.selectedSort.id !== 1 || _vm.search || _vm.selectedStatus.id !== 1)?_c(VChip,{staticClass:"mb-4",attrs:{"small":"","close":"","color":"error"},on:{"click:close":_vm.resetFilter}},[_vm._v(" Reset Filter ")]):_vm._e(),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.userList,"items-per-page":10,"item-key":"id","disable-sort":"","footer-props":{
          'items-per-page-options': [10, 25, 50],
          'items-per-page-text': 'Jumlah data',
        },"server-items-length":_vm.userCount,"mobile-breakpoint":"0","loading":_vm.loadingListUser,"options":_vm.userPagination},on:{"update:options":function($event){_vm.userPagination=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(props){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(props.item.id))])]}},{key:"item.name",fn:function(props){return [_c('router-link',{attrs:{"to":{
              name: 'admin-user-detail',
              params: { id: props.item.id },
            }}},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},[(props.item && props.item.photo !== null)?_c(VImg,{attrs:{"src":props.item.photo}}):_c('span',[_vm._v(_vm._s(_vm.avatarText(props.item.name)))])],1),_c('span',{staticClass:"ms-2"},[_vm._v(_vm._s(props.item.name))])],1)]}},{key:"item.phone",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.item.phone))])]}},{key:"item.action",fn:function(props){return [_c('router-link',{attrs:{"to":{
              name: 'admin-user-detail',
              params: { id: props.item.id },
            }}},[_c(VBtn,{attrs:{"color":"primary"}},[_vm._v(" Detail ")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }