<template>
  <div>
    <v-card>
      <v-row class="mx-2 pt-2">
        <v-col>
          <h3>Daftar User</h3>
        </v-col>
      </v-row>
      <v-divider class="my-4" />
      <div class="px-4 pb-4">
        <v-row class="mb-1">
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="selectedSort"
              :items="sortOptions"
              item-text="label"
              item-value="id"
              label="Sort"
              outlined
              dense
              class="w-25"
              return-object
              hide-details="auto"
              @change="changeSortUser"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-space-between flex-grow-1">
                  <div class="font-weight-bold">
                    <v-icon
                      class="me-1"
                      size="26"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <span class="font-weight-semibold"> {{ item.label }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="search"
              outlined
              dense
              label="Cari user"
              class="w-100"
              hide-details="auto"
              @change="searchUser"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="selectedStatus"
              :items="statusOptions"
              item-text="label"
              item-value="id"
              label="Status"
              outlined
              dense
              class="w-25"
              return-object
              hide-details="auto"
              @change="changeStatusUser"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-space-between flex-grow-1">
                  <div class="font-weight-bold">
                    <v-icon
                      class="me-1"
                      size="26"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <span class="font-weight-semibold"> {{ item.label }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-chip
          v-if="selectedSort.id !== 1 || search || selectedStatus.id !== 1"
          class="mb-4"
          small
          close
          color="error"
          @click:close="resetFilter"
        >
          Reset Filter
        </v-chip>
        <v-data-table
          :headers="headers"
          :items="userList"
          :items-per-page="10"
          item-key="id"
          disable-sort
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
            'items-per-page-text': 'Jumlah data',
          }"
          :server-items-length="userCount"
          mobile-breakpoint="0"
          :loading="loadingListUser"
          :options.sync="userPagination"
        >
          <template v-slot:[`item.id`]="props">
            <span class="font-weight-bold">{{ props.item.id }}</span>
          </template>
          <template v-slot:[`item.name`]="props">
            <router-link
              :to="{
                name: 'admin-user-detail',
                params: { id: props.item.id },
              }"
            >
              <v-avatar
                size="40px"
                color="primary"
                class="v-avatar-light-bg primary--text"
              >
                <v-img
                  v-if="props.item && props.item.photo !== null"
                  :src="props.item.photo"
                />
                <span v-else>{{ avatarText(props.item.name) }}</span>
              </v-avatar>
              <span class="ms-2">{{ props.item.name }}</span>
            </router-link>
          </template>
          <template v-slot:[`item.phone`]="props">
            <span>{{ props.item.phone }}</span>
          </template>
          <template v-slot:[`item.action`]="props">
            <router-link
              :to="{
                name: 'admin-user-detail',
                params: { id: props.item.id },
              }"
            >
              <v-btn
                color="primary"
              >
                Detail
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>
<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import useUser from '@/composables/useUser'
import { avatarText } from '@core/utils/filter'
import Vue from 'vue'
import { mdiSortAlphabeticalAscending, mdiSortAlphabeticalDescending } from '@mdi/js'

export default {
  setup() {
    const {
      fetchAllUsers, loadingListUser, userList, userCount,
      changeBanUser, loadingChangeBanUser,
    } = useUser()
    const headers = ref([
      { text: 'ID', value: 'id' },
      { text: 'Nama', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Phone', value: 'phone' },
      { text: 'Aksi', value: 'action' },
    ])
    const userPagination = ref({})
    const userFilter = ref({ sort: 'id', order: 'ASC' })
    const search = ref('')
    const fetchUser = () => {
      fetchAllUsers({
        limit: userPagination.value.itemsPerPage ? userPagination.value.itemsPerPage : 10,
        offset: userPagination.value.page ? (userPagination.value.page - 1) * userPagination.value.itemsPerPage : 0,
      },
      search.value,
      userFilter.value)
    }

    const selectedStatus = ref({
      id: 1, label: 'Semua', value: null,
    })

    const statusOptions = ref([
      { id: 1, label: 'Semua', value: null },
      { id: 2, label: 'Unverified', value: 1 },
      { id: 3, label: 'Verified', value: 2 },
      { id: 4, label: 'Banned', value: 3 },
    ])

    const selectedSort = ref({
      id: 1, label: 'ID Pengguna', field: 'id', type: 'ASC', icon: mdiSortAlphabeticalAscending,
    })
    const sortOptions = ref([
      {
        id: 1,
        label: 'ID Pengguna',
        field: 'id',
        type: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      },
      {
        id: 2,
        label: 'ID Pengguna',
        field: 'id',
        type: 'DESC',
        icon: mdiSortAlphabeticalDescending,
      },
      {
        id: 3,
        label: 'Nama Pengguna',
        field: 'name',
        type: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      },
      {
        id: 4,
        label: 'Nama Pengguna',
        field: 'name',
        type: 'DESC',
        icon: mdiSortAlphabeticalDescending,
      },
      {
        id: 5,
        label: 'Email Pengguna',
        field: 'email',
        type: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      },
      {
        id: 6,
        label: 'Email Pengguna',
        field: 'email',
        type: 'DESC',
        icon: mdiSortAlphabeticalDescending,
      },
      {
        id: 7,
        label: 'Telepon Pengguna',
        field: 'phone',
        type: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      },
      {
        id: 8,
        label: 'Telepon Pengguna',
        field: 'phone',
        type: 'DESC',
        icon: mdiSortAlphabeticalDescending,
      },
    ])
    onMounted(() => {
      fetchUser()
    })

    const changeSortUser = val => {
      userFilter.value.sort = val.field
      userFilter.value.order = val.type
      fetchUser()
    }

    const changeStatusUser = val => {
      userFilter.value.status_id = val.value
      fetchUser()
    }

    const searchUser = () => {
      fetchUser()
    }

    const confirmToggleBanUser = user => {
      Vue.$dialog({
        title: user.user_status.id === 3 ? 'Unban User' : 'Ban User',
        body: user.user_status.id === 3 ? 'Apakah anda yakin ingin unban user ini?' : 'Apakah anda yakin ingin ban user ini?',
      }).then(result => {
        if (result) {
          const newStatusId = user.user_status.id === 3 ? 2 : 3
          changeBanUser(user.id, newStatusId).then(() => {
            fetchUser()
          })
        }
      })
    }

    watch(userPagination, () => {
      fetchUser()
    })

    const resetFilter = () => {
      userFilter.value = { sort: 'id', order: 'ASC' }
      selectedSort.value = {
        id: 1, label: 'ID Pengguna', field: 'id', type: 'ASC', icon: mdiSortAlphabeticalAscending,
      }
      selectedStatus.value = {
        id: 1, label: 'Semua', value: null,
      }
      search.value = ''
      fetchUser()
    }

    return {
      headers,
      loadingListUser,
      userList,
      userCount,
      userPagination,
      avatarText,
      confirmToggleBanUser,
      loadingChangeBanUser,
      selectedSort,
      sortOptions,
      changeSortUser,
      searchUser,
      search,
      selectedStatus,
      statusOptions,
      changeStatusUser,
      resetFilter,
    }
  },
}
</script>
